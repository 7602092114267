<template>
    <div>
        <div v-for="invoice in row.item.invoice_documents">
            <div v-if="invoice.url">
                <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;
                <a target="_blank"
                   @click.prevent="$root.openDocument(invoice.name)"
                   :href="$root.getDocumentUrl(invoice.name)"
                >{{ invoice.name }}</a>
            </div>
            <div v-else>{{ invoice.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>